<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-appointment-detail"
    title="Appointment Detail"
    no-close-on-backdrop
    hide-footer="true"
    scrollable
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <small>Curtis Stone</small>
      <h6 class="text-muted">
        15/05/2021
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
        HMO Medi-Cal
      </b-badge>
      <hr class="mb-2">
      <div class="list text-left">
        <div class="list-item">
          <div class="list-body">
            <p>Appointment set for <b><span class="text-info">08/28/2021</span></b> at <b>COMPTON</b> with <b>JACLYN</b> for a HMO Medi-Cal evaluation
            </p>
            <p>Source: <b>PCPC Logs</b> as a
              <b>CPE</b>
            </p>
            <small class="block text-muted">created by Kelyn Sandoval on 2021-08-18 07:28:00</small>
          </div>
        </div>
      </div>
    </b-card>
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        icon="CalendarIcon"
        variant="success"
      >
        <div class="d-flex align-items-start flex-sm-row flex-wrap justify-content-between mb-1 mb-sm-0">
          <small>Appointment Date <b><span class="text-info">29/03/2021</span></b> </small>
          <b-badge
            pill
            variant="light-primary"
          >
            Design
          </b-badge>
        </div>
        <div style="display: grid; margin-top: 15px; margin-bottom: 15px;">
          <small>At: <b><span class="text-info">Compton</span></b> </small>
          <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
            <small>Updated By: <b><span class="text-info">Kelyn Sandoval</span></b></small>
            <div>
              <feather-icon
                icon="MessageSquareIcon"
                class="mr-1"
              />
            </div>
          </div>
          <small class="block text-muted">Created On: 04/01/2021 </small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BAvatar, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BModal,
    BCard,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>
