<template>
  <b-card
    class="profile-header mb-2"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile navbar -->
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
        style="background: transparent !important;"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            style="font-size: 10px; padding: 0.586rem 1.5rem !important;"
            class="mr-50"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">New Appointment</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            style="font-size: 10px; padding: 0.586rem 1.5rem !important;"
            class="mr-50"
          >
            <feather-icon
              icon="CheckSquareIcon"
              class="mr-50"
            />
            <span class="align-middle">Create Last Visit</span>
          </b-button>

        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BNavbar,
    BNavbarToggle,
    BCollapse,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
@media (min-width: 768px){
  [dir=ltr] .profile-header-nav .profile-tabs {
      margin-left: 0px !important;
  }
}
</style>
