<template>
  <b-card>
    <div class="mb-75">
      <small style="font-weight: bold;">Preferred Communications</small>
      <b-card-text>
        <small>(323) 385-7708</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Address</small>
      <b-card-text>
        <small>8637 GRAPE ST 90002 Los Angeles , California United States</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Transportation</small>
      <b-card-text>
        <small>No</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Details</small>
      <b-card-text>
        <small>Status: 1</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Source</small>
      <b-card-text>
        <small>IN-REACH NEW</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Coverage Type</small>
      <b-card-text>
        <small>HMO Medi-Cal</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Enrollment Type</small>
      <b-card-text>
        <small>New Member</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Prior Health Plan</small>
      <b-card-text>
        <small>LA CARE</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Health Plan</small>
      <b-card-text>
        <small>LA CARE</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">IPA</small>
      <b-card-text>
        <small>Preferred</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Staff Name</small>
      <b-card-text>
        <small>Maria G. Jacobo</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Clinic</small>
      <b-card-text>
        <small>COMPTON</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Confirmation Number</small>
      <b-card-text>
        <small />
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Medi-Cal</small>
      <b-card-text>
        <small>98619600E</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">PCPC</small>
      <b-card-text>
        <small>Jul 6 2020</small>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
