<template>
  <b-card>
    <h5>Information</h5>

    <!-- Information feed -->
    <div
      class="profile-twitter-feed mt-2"
    >
      <div class="d-flex justify-content-start align-items-center mb-1">
        <b-avatar
          size="40"
          src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/avatar-s-2.da5e73c7.jpg"
          class="mr-1"
        />
        <div class="profile-user-info">
          <h6 class="mb-0">
            Kitty Allison
          </h6>
          <b-link>
            <small class="text-muted">#:456458 </small>
            <feather-icon icon="CheckCircleIcon" />
          </b-link>
        </div>
      </div>
      <b-card-text class="mb-50">
        <h6>Age: 18</h6>
        <h6>DOB: Mar/1/2003</h6>
      </b-card-text>
      <b-link>
        <small class="text-muted ">
          Appts History:
        </small>
        <br>
        <b-row>
          <!-- about suggested page and twitter feed -->
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Scheduled: 6</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Re-Scheduled: 6</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Confirmed: 6</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />No show: 6</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Ocurred: 6</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Canceled: 6</span>
          </b-col>
        </b-row>
      </b-link>
    </div>
    <!-- twitter feed -->
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BLink, BCardText, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BAvatar, BLink, BCardText, BRow, BCol,
  },
}
</script>

<style>
.fc-event-dot{
      display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
}
</style>
