<template>
  <b-tabs pills>
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <kardex-profile-about />
        <kardex-profile-suggested-info />
      </b-col>
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-tab
          title="Appointment Feed"
          active
        >
          <div id="user-profile">
            <!--/ profile info  -->
            <section id="profile-info">
              <kardex-profile-header />
              <kardex-profile-record />
            </section>
            <!--/ profile info  -->
          </div>
        </b-tab>
        <b-tab title="Activity">
          h
        </b-tab>
      </b-col>
    </b-row>
  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs,
} from 'bootstrap-vue'
import KardexProfileAbout from './KardexProfileAbout.vue'
import KardexProfileHeader from './appointmentFeed/KardexProfileHeader.vue'
import KardexProfileSuggestedInfo from './KardexProfileSuggestedInfo.vue'
import KardexProfileRecord from './appointmentFeed/KardexProfileRecord.vue'
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    KardexProfileAbout,
    KardexProfileSuggestedInfo,
    KardexProfileRecord,
    KardexProfileHeader,
    BTab,
    BTabs,
  },
  data() {
    return {
      profileData: { },
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
